import html2canvas from "html2canvas";
import axios from "axios";
import { BASE_URL } from "../environment";
import { shareUrlWithFriends } from "../environment";

export const sharePredictionsHandler = async (
  ref,
  setIsDisplayed,
  predictionsOrResults,
  setBlob,
  setImage,
  setShareLoading,
  roundId
) => {
  try {
    const canvas = await html2canvas(ref.current, {
      logging: true,
      allowTaint: true,
      useCORS: true,
      scale: 2,
      onclone: (cloneDoc) => {
        let refsHeaderContainer = cloneDoc.querySelector(".header-container");
        if (refsHeaderContainer) {
          refsHeaderContainer.style = {};
        }
      },
    });
    if (canvas) {
      const base = canvas.toDataURL("image/png");
      if (Boolean(sessionStorage.getItem("isMobile"))) {
        sessionStorage.getItem("os") === "ios"
          ? window.webkit.messageHandlers.observer.postMessage(
              JSON.stringify({
                data: {
                  link: base,
                  text: shareUrlWithFriends,
                },
              })
            )
          : window.Android.postMessage(
              JSON.stringify({
                url: "shareScreenshot",
                data: {
                  link: base,
                  text: shareUrlWithFriends,
                },
              })
            );
      } else {
        canvas.toBlob(async (blob) => {
          const file = new FormData();
          file.append("screenshot", blob, "screenshot.jpg");
          file.append("page", predictionsOrResults);
          file.append("roundId", roundId);
          const {
            data: { url },
          } = await axios.post(`${BASE_URL}/api/create-screenshot-url`, file, {
            headers: {
              "content-type": "multipart/form-data",
            },
          });
          if (url) {
            setIsDisplayed(true);
            setBlob(blob);
            setImage(url);
            setShareLoading(false);
          }
        });
      }
    }
  } catch (err) {
    console.log(err);
  }
};
